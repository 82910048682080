import tw, { css, styled, TwStyle } from 'twin.macro';

import { Color, ThickNess } from './ProgressBar';

const thicknessStyles: Record<ThickNess, TwStyle> = {
	fat: tw`h-2`,
	thin: tw`h-1`,
};

const colorStyles: Record<Color, TwStyle> = {
	green: tw`bg-green-600`,
	indigo: tw`bg-indigo-500`,
};

const getColorStyle = (color: Color): TwStyle => {
	return colorStyles[color];
};

export const StyledProgressBar = styled.div<{
	completionRate: number;
	timer?: number;
	indeterminate?: boolean;
	thickness?: ThickNess;
	color?: Color;
}>(({ completionRate, timer, indeterminate, thickness, color }) => [
	tw`w-full relative rounded`,
	({ thickness }) => thicknessStyles[thickness ?? 'fat'],
	css`
		overflow: hidden;

		@keyframes load {
			from {
				transform: scaleX(0);
			}
			to {
				transform: scaleX(1);
			}
		}

		@keyframes indeterminate {
			0% {
				transform: translateX(0) scaleX(0);
			}
			40% {
				transform: translateX(0) scaleX(0.4);
			}
			100% {
				transform: translateX(100%) scaleX(0.5);
			}
		}

		&::before {
			content: '';
			display: block;
			${tw`bg-gray-100 w-full h-full absolute`}
		}

		&::after {
			content: '';
			display: block;
			width: ${100}%;
			transition: transform 300ms;
			transform: scaleX(${!timer ? completionRate / 100 : 1});
			transform-origin: left center;
			${getColorStyle(color ?? 'indigo')};
			${tw`h-full absolute`}
		}
	`,
	timer &&
		css`
			&::after {
				will-change: transform;
				animation-name: load;
				animation-duration: ${timer}ms;
				animation-iteration-count: 1;
				animation-timing-function: linear;
			}
		`,
	indeterminate &&
		css`
			&::after {
				will-change: transform;
				animation-name: indeterminate;
				animation-duration: ${timer || 1000}ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}
		`,
]);

import { DateTime } from '@cheqroom/date-time';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export interface InitiateRequest {
	email: string;
}

export interface InitiateSsoResponse {
	redirectUrl?: string;
}

export class InitiateError extends Error {
	public readonly status: number;

	constructor(message: string, status: number) {
		super(message);
		this.status = status;
	}
}

const AUTH_URL = process.env.AUTH_URL as string;

const doInitiate = async ({ email }: InitiateRequest): Promise<void> => {
	const timezone = DateTime.getTimeZone();

	const response = await fetch(`${AUTH_URL}/initiate`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Time-Zone': timezone },
		body: JSON.stringify({ email }),
	});

	if (!response.ok) {
		throw new InitiateError(`Unable to initiate ${response.status}`, response.status);
	}
};

export const useDoInitiate = (
	options?: UseMutationOptions<void, unknown, InitiateRequest, unknown>
): UseMutationResult<void, InitiateError, InitiateRequest, unknown> => {
	return useMutation<void, InitiateError, InitiateRequest, unknown>(
		['initiate'],
		(data) => doInitiate({ email: data.email }),
		options
	);
};

const doInitiateWithSso = async ({ email }: InitiateRequest): Promise<InitiateSsoResponse> => {
	const timezone = DateTime.getTimeZone();

	const response = await fetch(`${AUTH_URL}/initiate/sso`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Time-Zone': timezone },
		body: JSON.stringify({ email }),
	});

	if (!response.ok) {
		throw new InitiateError(`Unable to initiate authentication via sso: ${response.status}`, response.status);
	}

	if (response.status === 204) {
		return {};
	}

	return response.json() as InitiateSsoResponse;
};

export const useDoInitiateWithSso = (
	options?: UseMutationOptions<InitiateSsoResponse, unknown, InitiateRequest, unknown>
): UseMutationResult<InitiateSsoResponse, InitiateError, InitiateRequest, unknown> => {
	return useMutation<InitiateSsoResponse, InitiateError, InitiateRequest, unknown>(
		['initiate'],
		(data) => doInitiateWithSso({ email: data.email }),
		options
	);
};

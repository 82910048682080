import 'twin.macro';

import { useSessionStorage } from '@cheqroom/hooks';
import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { StyledLink } from './App.styles';
import { AuthenticateWithSso } from './components/AuthenticateWithSsoStep/AuthenticateWithSso';
import { CodeVerificationStep } from './components/CodeVerificationStep/CodeVerificationStep';
import { EmailStep } from './components/EmailStep/EmailStep';
import { FindWorkspaceStep } from './components/FindWorkspacesStep/FindWorkspacesStep';
import { ForgotPasswordStep } from './components/ForgotPasswordStep/ForgotPasswordStep';
import { LogoutStep } from './components/LogoutStep/LogoutStep';
import { Page } from './components/Page/Page';
import { PasswordOrSSOStep } from './components/PasswordOrSSOStep/PasswordOrSSOStep';
import { PasswordStep } from './components/PasswordStep/PasswordStep';
import { ResetPasswordStep } from './components/ResetPasswordStep/ResetPasswordStep';
import { SelectAuthenticationStrategyStep } from './components/SelectAuthenticationStrategyStep/SelectAuthenticationStrategyStep';
import { SelectOrganisationStep } from './components/SelectOrganisationStep/SelectOrganisationStep';
import { SelectWorkspaceStep } from './components/SelectWorkspaceStep/SelectWorkspaceStep';
import { SetPasswordStep } from './components/SetPasswordStep/SetPasswordStep';
import { SsoRedirectStep } from './components/SSORedirectStep/SSORedirectStep';

export enum Step {
	ENTER_EMAIL = '/email',
	FORGOT_PASSWORD = '/forgot',
	FIND_WORKSPACES = '/find-workspaces',
	PASSWORD_OR_SSO = '/password-or-sso',
	RESET_PASSWORD = '/reset-password',
	SET_PASSWORD = '/set-password',
	SELECT_AUTHENTICATION_MULTI_STRATEGY_STEP = '/:workspaceId/login',
	AUTHENTICATION_STRATEGY_PASSWORD_STEP = '/:workspaceId/login/strategy/password',
	AUTHENTICATION_STRATEGY_LDAP_STEP = '/:workspaceId/login/strategy/ldap',
	AUTHENTICATION_STRATEGY_SSO_STEP = '/:workspaceId/login/strategy/sso',
	SELECT_WORKSPACE = '/workspaces',
	SELECT_ORGANISATION = '/organisations',
	SSO_REDIRECT = '/sso',
	CONFIRM_EMAIL = '/confirm-email',
	LOGOUT = '/logout',
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: FC = () => {
	const [params] = useSearchParams();

	useSessionStorage<boolean>('mobile', params.has('mobile') ? params.get('mobile') === 'true' : undefined);
	useSessionStorage('CHEQROOM_REDIRECT_KEY', params.get('redirect_uri'));

	return (
		<Page>
			<Page.Content>
				<SentryRoutes>
					<Route path={Step.PASSWORD_OR_SSO} element={<PasswordOrSSOStep />} />
					<Route path={Step.SELECT_WORKSPACE} element={<SelectWorkspaceStep />} />
					<Route path={Step.FIND_WORKSPACES} element={<FindWorkspaceStep />} />
					<Route path={Step.ENTER_EMAIL} element={<EmailStep />} />
					<Route path={Step.FORGOT_PASSWORD} element={<ForgotPasswordStep />} />
					<Route path={Step.RESET_PASSWORD} element={<ResetPasswordStep />} />
					<Route path={Step.SET_PASSWORD} element={<SetPasswordStep />} />
					<Route path={Step.SSO_REDIRECT} element={<SsoRedirectStep />} />
					<Route path={Step.CONFIRM_EMAIL} element={<CodeVerificationStep />} />
					<Route path={Step.SELECT_ORGANISATION} element={<SelectOrganisationStep />} />
					<Route path={Step.AUTHENTICATION_STRATEGY_PASSWORD_STEP} element={<PasswordStep />} />
					<Route path={Step.AUTHENTICATION_STRATEGY_SSO_STEP} element={<AuthenticateWithSso />} />
					<Route path={Step.AUTHENTICATION_STRATEGY_LDAP_STEP} element={<PasswordStep />} />
					<Route
						path={Step.SELECT_AUTHENTICATION_MULTI_STRATEGY_STEP}
						element={<SelectAuthenticationStrategyStep />}
					/>
					<Route path={Step.LOGOUT} element={<LogoutStep />} />

					<Route path="/*" element={<EmailStep />} />
				</SentryRoutes>
			</Page.Content>
			<Page.Footer>
				<Trans
					i18nKey="general.contact_support"
					ns="login"
					components={[
						<StyledLink key="0" url="https://cheqroom.com/" external>
							placeholder
						</StyledLink>,
						<StyledLink key="1" url="https://help.cheqroom.com/" external>
							placeholder
						</StyledLink>,
					]}
				/>
			</Page.Footer>
		</Page>
	);
};

import 'twin.macro';

import { LoadingSpinner } from '@cheqroom/ui';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Workspace } from '../../api/discovery.api';
import { useGetWorkspaces } from '../../api/find-workspaces.api';
import { Step } from '../../App';
import { useClearSession, useDiscovery } from './api.hooks';
import { MultiStrategyLoginForm } from './MultiStrategyLoginForm';

export const SelectAuthenticationStrategyStep: React.FC = () => {
	const navigateTo = useNavigate();

	// With cookie
	const { data, status: discoveryStatus } = useDiscovery();
	const { mutate: clearSession } = useClearSession();
	const handleGoBackWithCookie = () => {
		if (!!data.workspaces && data.workspaces.length > 1) {
			return navigateTo(Step.SELECT_WORKSPACE);
		}

		clearSession();
	};

	// Without cookie
	const { workspaceId } = useParams<{ workspaceId: string }>();
	const { data: workspacesData, status: workspaceStatus } = useGetWorkspaces(
		{
			ids: workspaceId ? [workspaceId] : [],
		},
		{
			enabled: !!workspaceId,
		}
	);
	const workspace = workspacesData?.workspaces?.[0] as (Workspace & { url: string }) | undefined;

	useEffect(() => {
		if (discoveryStatus === 'error') {
			if (!workspaceId || (workspaceStatus !== 'loading' && !workspace)) {
				return navigateTo(Step.ENTER_EMAIL);
			}

			if (workspaceId && workspace) {
				navigateTo(workspace.url);
			}
		}
	}, [discoveryStatus, workspaceStatus, workspace, workspaceId]);

	if (discoveryStatus === 'loading' || workspaceStatus === 'loading') {
		return <LoadingSpinner size="large" />;
	}

	// With cookie
	if (discoveryStatus === 'success') {
		return (
			<MultiStrategyLoginForm
				workspace={data.workspace}
				email={data.email}
				onGoBackClick={handleGoBackWithCookie}
			/>
		);
	}

	// Without cookie
	const handleGoBackWithoutCookie = () => {
		return navigateTo(Step.ENTER_EMAIL);
	};

	if (!workspace) return null;

	return <MultiStrategyLoginForm workspace={workspace} onGoBackClick={handleGoBackWithoutCookie} />;
};

import { FC } from 'react';

import { Stack } from '../Stack/Stack';
import { TextStyle } from '../Typography/TextStyle/TextStyle';
import { StyledProgressBar } from './ProgressBar.styles';

export type ThickNess = 'fat' | 'thin';

export type Color = 'green' | 'indigo';

export type Props = {
	count: number;
	max: number;
	timer?: boolean;
	indeterminate?: boolean;
	thickness?: ThickNess;
	color?: Color;
	showPercentage?: boolean;
};

export const ProgressBar: FC<Props> = ({
	count = 0,
	max,
	timer = false,
	indeterminate = false,
	thickness = 'thin',
	color = 'indigo',
	showPercentage = false,
}) => {
	const completionRate = Math.ceil((count / max) * 100);
	return (
		<Stack spacing="tight" align="center">
			<StyledProgressBar
				completionRate={completionRate}
				timer={timer ? max : undefined}
				indeterminate={indeterminate}
				thickness={thickness}
				color={color}
			/>
			{showPercentage && <TextStyle variation="subdued">{completionRate}%</TextStyle>}
		</Stack>
	);
};

export default ProgressBar;

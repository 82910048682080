import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

const AUTH_URL = process.env.AUTH_URL as string;

export interface ClearSessionResponse {
	singleLogoutUrls: string[];
}

export const doClearSession = async (): Promise<void> => {
	const response = await fetch(`${AUTH_URL}/clear-session?cache=${new Date().toISOString()}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		credentials: 'include',
	});

	if (!response.ok) {
		throw new Error(`Unable to clear the session: ${response.status}`);
	}

	// Trigger logout on all SSO IdPs for given user identity
	const { singleLogoutUrls } = (await response.json()) as ClearSessionResponse;
	if (singleLogoutUrls.length > 0) {
		singleLogoutUrls.forEach((singleLogoutUrl, index) => {
			// Most browser only allow to open one window at a time, so we open the first one in the same window
			window.open(singleLogoutUrl, index === 0 ? '_self' : '_blank');
		});
	}
};

export const useDoClearSession = (
	options?: UseMutationOptions<void>
): UseMutationResult<void, unknown, void, unknown> => {
	return useMutation<void>(['clear-session'], () => doClearSession(), options);
};

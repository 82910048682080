import './services/i18n';

import { StorageProvider } from '@cheqroom/hooks/src/use-storage';
import { ThemeProvider } from '@cheqroom/ui';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { GlobalStyle } from './App.styles';
import { GlobalErrorBoundary } from './components/GlobalErrorBoundary/GlobalErrorBoundary';
import { initSentry } from './services/sentry';

const featureFlagsConfig = createInstance({
	sdkKey: process.env.OPTIMIZELY_KEY,
	datafileOptions: {
		autoUpdate: true,
		updateInterval: 30 * 60 * 1000, // 30 minutes,
		...(process.env.OPTIMIZELY_PROXY &&
			process.env.OPTIMIZELY_KEY && {
				urlTemplate: `${process.env.OPTIMIZELY_PROXY}/datafiles/%s.json`,
			}),
	},
});

initSentry();

if (process.env.ENVIRONMENT === 'development') {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		const localFeatureFlagsOverride = (await require('../../../feature-flags.json')) as {
			[key: string]: boolean;
		};
		const superMethod = featureFlagsConfig.isFeatureEnabled;

		featureFlagsConfig.isFeatureEnabled = (key: string): boolean => {
			if (localFeatureFlagsOverride) {
				if (key in localFeatureFlagsOverride) {
					return localFeatureFlagsOverride[key];
				}
			}

			return superMethod.apply(featureFlagsConfig, [key]);
		};
		// eslint-disable-next-line
	} catch (error) {}
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnWindowFocus: false,
		},
	},
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
	<Suspense fallback={null}>
		<StrictMode>
			<ThemeProvider>
				<GlobalErrorBoundary>
					<BrowserRouter>
						<OptimizelyProvider optimizely={featureFlagsConfig} user={{ id: 'login-page' }}>
							<QueryClientProvider client={queryClient}>
								<StorageProvider>
									<GlobalStyle />
									<App />
								</StorageProvider>
							</QueryClientProvider>
						</OptimizelyProvider>
					</BrowserRouter>
				</GlobalErrorBoundary>
			</ThemeProvider>
		</StrictMode>
	</Suspense>
);

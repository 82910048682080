import { useSessionStorage } from '@cheqroom/hooks';
import { LoadingSpinner } from '@cheqroom/ui';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Workspace } from '../../api/discovery.api';
import { useGetWorkspaces } from '../../api/find-workspaces.api';
import { Step } from '../../App';
import { useLoadDiscovery } from './api.hooks';
import PasswordLoginForm from './PasswordLoginForm';

export const PasswordStep: FC = () => {
	// With cookie
	const { status: discoveryStatus, workspace: discoveryWorkspace, email, onGoBack } = useLoadDiscovery();

	// Without cookie
	const navigateTo = useNavigate();
	const { workspaceId } = useParams<{ workspaceId: string }>();
	const { data: workspacesData, status: recentWorkspacesStatus } = useGetWorkspaces(
		{ ids: workspaceId ? [workspaceId] : [] },
		{ enabled: !!workspaceId }
	);
	const [emailFromSession] = useSessionStorage<string>('email');

	if (discoveryStatus === 'loading' || recentWorkspacesStatus === 'loading') {
		return <LoadingSpinner size="large" />;
	}

	// With cookie
	if (discoveryStatus === 'success') {
		return (
			<PasswordLoginForm
				workspace={discoveryWorkspace}
				email={email}
				onGoBackClick={onGoBack}
				canChangeEmail={!email}
			/>
		);
	}

	// Without cookie
	const workspace = workspacesData?.workspaces?.[0] as (Workspace & { url: string }) | undefined;
	const handleGoBackClick = () => {
		navigateTo(Step.ENTER_EMAIL);
	};

	return (
		<PasswordLoginForm
			workspace={workspace}
			onGoBackClick={handleGoBackClick}
			email={emailFromSession}
			canChangeEmail
		/>
	);
};

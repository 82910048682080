import 'twin.macro';

import { Logo } from '@cheqroom/illustrations';
import { Illustration, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import { Step } from '../../App';
import { StyledCard } from '../../App.styles';
import { useLoginWithOAuthCode, useNewLoginWithOauthCode } from './api.hooks';

export const SsoRedirectStep: FC = () => {
	const [params] = useSearchParams();

	if (params.get('error_description')) {
		return (
			<Navigate
				to={Step.SELECT_WORKSPACE}
				state={{
					error: params.get('error_description'),
				}}
			/>
		);
	}

	return <OldSsoRedirectStep />;
};

const OldSsoRedirectStep: FC = () => {
	const [params] = useSearchParams();
	const { t } = useTranslation();

	useLoginWithOAuthCode();

	// Get the params from the url and use them to identify with the python API
	const code = params.get('code');
	const state = params.get('state');

	if (!code || !state) {
		return <Navigate to={Step.ENTER_EMAIL} />;
	}

	return (
		<StyledCard sectioned spacing="large">
			<Stack align="center" vertical spacing="loose">
				<Illustration source={Logo} tw="mb-4" />

				<Text align="center">{t('sso_redirect_step.redirect_message')}</Text>
			</Stack>
		</StyledCard>
	);
};

const NewSsoRedirectStep: FC = () => {
	const [params] = useSearchParams();
	const { t } = useTranslation();

	useNewLoginWithOauthCode();

	// Get the params from the url and use them to identify with the python API
	const code = params.get('code');
	const state = params.get('state');

	if (!code || !state) {
		return <Navigate to={Step.ENTER_EMAIL} />;
	}

	return (
		<StyledCard sectioned>
			<Stack align="center" vertical spacing="loose">
				<Illustration source={Logo} tw="mb-4" />

				<Text align="center">{t('sso_redirect_step.redirect_message')}</Text>
			</Stack>
		</StyledCard>
	);
};

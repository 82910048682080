import { useLocalStorage } from '@cheqroom/hooks';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { AuthenticationStrategy } from './discovery.api';

const AUTH_URL = process.env.AUTH_URL as string;
const RECENT_WORKSPACES_KEY = 'recent_workspaces';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DiscoveryRequest {}

export interface SsoWorkspace {
	id: string;
	name: string;
}

export interface DiscoveryResponse {
	email: string;
	workspaces: SsoWorkspace[];
}

export const doDiscoverWorkspaces = async (_: DiscoveryRequest, init?: RequestInit): Promise<DiscoveryResponse> => {
	const response = await fetch(new URL(`${AUTH_URL}/workspaces/discover`), {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		credentials: 'include',
		...init,
	});

	if (!response.ok) {
		throw new Error(`Unable to get the workspace discovery: ${response.status}`);
	}

	return response.json() as Promise<DiscoveryResponse>;
};

export const useDoDiscoverWorkspaces = <TData>(
	_: DiscoveryRequest,
	options?: UseQueryOptions<DiscoveryResponse, unknown, TData>
): UseQueryResult<TData> => {
	return useQuery<DiscoveryResponse, unknown, TData, QueryKey>(
		['discover-workspaces'],
		({ signal }) => doDiscoverWorkspaces({}, { signal }),
		options
	);
};

interface WorkspacesRequest {
	ids: string[];
}

interface WorkspaceWithStrategies {
	id: string;
	name: string;
	logo?: string;
	strategies: AuthenticationStrategy[];
}

interface WorkspacesResponse {
	workspaces: WorkspaceWithStrategies[];
}

const doGetWorkspaces = async ({ ids }: WorkspacesRequest, init?: RequestInit): Promise<WorkspacesResponse> => {
	const response = await fetch(new URL(`${AUTH_URL}/workspaces/strategies`), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		credentials: 'include',
		...init,
		body: JSON.stringify({ ids }),
	});

	if (!response.ok) {
		throw new Error(`Unable to get the workspace discovery: ${response.status}`);
	}

	return response.json() as Promise<WorkspacesResponse>;
};

export const useGetWorkspaces = (
	payload: WorkspacesRequest,
	options?: UseQueryOptions<WorkspacesResponse, Error>
): UseQueryResult<WorkspacesResponse, Error> => {
	return useQuery<WorkspacesResponse, Error>(
		['workspace-strategies', payload],
		async ({ signal }) => doGetWorkspaces(payload, { signal }),
		{
			...options,
			select: (data) => {
				return {
					workspaces: data?.workspaces?.map((workspace) => {
						let url = `/${workspace.id}/login/strategy/password`;

						if (workspace.strategies.includes(AuthenticationStrategy.SSO)) {
							url = `/${workspace.id}/login`;
						}

						return {
							...workspace,
							url,
						};
					}),
				};
			},
		}
	);
};

export const useRecentWorkspaces = () => {
	const [workspaceIds = [], updateWorkspaces, clearWorkspaces] = useLocalStorage<string[]>(RECENT_WORKSPACES_KEY, []);
	const hasRecentWorkspaces = !!workspaceIds.length;
	const {
		data: { workspaces } = { workspaces: [] },
		status,
		fetchStatus,
	} = useGetWorkspaces(
		{
			ids: workspaceIds,
		},
		{
			enabled: hasRecentWorkspaces,
			staleTime: 10000,
		}
	);

	useEffect(() => {
		// Update local storage (removes workspaces that don't exist anymore)
		const workspacesResponseIds = workspaces.map((workspace) => workspace.id);

		if (
			(status === 'success' || (status === 'loading' && fetchStatus === 'idle')) &&
			JSON.stringify(workspacesResponseIds) !== JSON.stringify(workspaceIds)
		) {
			updateWorkspaces(workspaceIds.filter((id) => workspacesResponseIds.includes(id)));
		}
	}, [status, fetchStatus, workspaceIds, workspaces]);

	const addRecentWorkspace = (workspaceId: string) => {
		updateWorkspaces([workspaceId, ...workspaceIds.filter((id) => id !== workspaceId)]);
	};
	const removeRecentWorkspace = (workspaceId: string) =>
		updateWorkspaces(workspaceIds.filter((id) => id !== workspaceId));

	const workspacesWithUrls = workspaces.sort((a, b) => {
		const aIndex = workspaceIds.findIndex((id) => id === a.id);
		const bIndex = workspaceIds.findIndex((id) => id === b.id);

		return aIndex - bIndex;
	});

	return {
		workspaces: workspacesWithUrls as (WorkspaceWithStrategies & { url: string })[],
		// If no workspaces need to be fetched, status will return loading,
		// but we want to return success
		status: status === 'loading' && fetchStatus === 'idle' ? 'success' : status,
		total: workspaceIds.length,
		update: updateWorkspaces,
		clear: clearWorkspaces,
		add: addRecentWorkspace,
		remove: removeRecentWorkspace,
	};
};

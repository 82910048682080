import 'twin.macro';

import { Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecentWorkspaces } from '../../api/find-workspaces.api';
import { Workspace } from '../SelectWorkspaceStep/Workspace';
import { StyledWorkspacesList } from './styles';

export const RecentWorkspaces: FC = () => {
	const { t } = useTranslation('login', {
		keyPrefix: 'email_step',
	});

	const { workspaces: recentWorkspaces, total: totalRecentWorkspaces, status } = useRecentWorkspaces();

	if (totalRecentWorkspaces === 0 || status === 'error') return;

	return (
		<>
			<Stack vertical spacing="loose">
				<Text weight="semibold" size="medium" align="center">
					{t('recent_workspaces.header')}
				</Text>
				<StyledWorkspacesList vertical spacing="tight">
					{status === 'loading'
						? Array(totalRecentWorkspaces)
								.fill('')
								.map((_, i) => <Workspace.Skeleton key={i} />)
						: recentWorkspaces.map((workspace) => (
								<Workspace
									key={workspace.id}
									workspace={workspace}
									ariaLabel={t('recent_workspaces.aria_label', { name: workspace.name })}
									to={workspace.url}
								/>
							))}
				</StyledWorkspacesList>
			</Stack>
			<hr tw="bg-gray-200 -mx-6 sm:-mx-12" />
			<Stack vertical>
				<Text align="center" weight="semibold">
					{t('workspace_not_found.header')}
				</Text>
				<Text align="center" color="subdued">
					{t('workspace_not_found.subtext')}
				</Text>
			</Stack>
		</>
	);
};
